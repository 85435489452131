<template>
  <section class="laderboard">
    <div v-if="loading">
      <div class="pixel-loader"></div>
    </div>
    <div v-else>
      <div class="row">
        <span class="card-title h3">{{$t('ranking')}}</span>
        <bet-rank :rankList="weekRankings" :deadline="endOfWeek" :title="GetFormattedDate(RangeOfWeek['start']) + '~' + GetFormattedDate(RangeOfWeek['end']) + ' ' "/>
      </div>
      <div class="row">
        <bet-rank :rankList="rankings" :title="$t('alltime')"/>
      </div>
    </div>
    <UserDetail />
  </section>
</template>

<script lang="js">
import UserDetail from "@/pages/dialogs/userDetail";
import betRank from '@/pages/share/bet-rank';

export default {
  name: 'leaderboard',
  components: {
    UserDetail,
    betRank
  },
  data() {
    return {
      loading: true,
      transactionLabels: [this.$t('status.open'), this.$t('status.won'), this.$t('status.lost')],
      transactionPercetages: [100, 0, 0],
      rankings: [],
      weekRankings: [],
      endOfWeek: '',
      RangeOfWeek: {}
    };
  },
  created () {
    this.$bobwinHub.$on('leaderBoardReceived', this.onLeaderBoardReceived);

    this.loading = true;
    this.getDashboard();
  },
  beforeDestroy () {
    this.$bobwinHub.$off('leaderBoardReceived', this.onLeaderBoardReceived);
  },
  methods: {
    init () {
      if (Array.isArray(this.rankings) && Array.isArray(this.weekRankings)) {
        const monday = this.getMondayDate();
        const sunday = this.addDaysToDate(monday, 7);
        this.endOfWeek = sunday;
        this.RangeOfWeek = { start: monday, end: sunday };
        this.loading = false;
      }
    },
    getUtcNow() {
      const date = new Date();
      const now_utc = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(),
                date.getUTCDate(), date.getUTCHours(),
                date.getUTCMinutes(), date.getUTCSeconds());
      return new Date(now_utc);
    },
    getMondayDate () {
      const today = this.getUtcNow();
      const dayOfWeek = today.getUTCDay();
      const diff = today.getUTCDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1);
      const utcMonday = Date.UTC(today.getUTCFullYear(), today.getUTCMonth(), diff, 0, 0, 0);
      const monday = new Date(utcMonday);
      return monday;
    },
    addDaysToDate(date, days) {
      const result = new Date(date);
      result.setUTCDate(result.getUTCDate() + days);
      return result;
    },
    getDashboard () {
      this.CallHub({task: 'GetLeaderboard', callback: 'leaderBoardReceived'});
    },
    onLeaderBoardReceived (response) {
      const dashboardData = JSON.parse(response.data);
      this.rankings = dashboardData.rankings.sort(function (a,b) {
          return a.amount > b.amount ? -1 : 1;
        });
      this.weekRankings = dashboardData.weekRankings.sort(function (a,b) {
          return a.amount > b.amount ? -1 : 1;
        });
      this.init();
    },
  }
}
</script>


<style lang="scss" scoped>
.overflow-height {
  height: 500px;
}
.sortable tr {
  cursor: pointer;
}

</style>