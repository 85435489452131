<template>
    <div class="col-12 grid-margin">
        <div class="card">
        <div class="card-body">
          
            <span class="card-title h3">{{title}}</span><span class="h6">{{$t('userClickDetail')}}</span>
            <div class="text-info text-center mt-1 mb-4" >
              <countdown v-if="deadline != null" :deadline="endDate" />
              <span v-if="deadline != null">{{$t("endDate") + ': ' + GetFormattedDatetime(deadline)}}</span>
            </div>
            <perfect-scrollbar>
                <div class=" overflow-height">
                    <div class="table-responsive">
                        <table class="table">
                            <thead>
                            <tr>
                                <th> {{$t('rank')}} </th>
                                <th> {{$t('user')}} </th>
                                <th> {{$t('profitLoss')}} </th>
                                <th> {{$t('betAmount')}} </th>
                                <th> {{$t('roi')}} </th>
                                <th> {{$t('numberOfWon')}} </th>
                                <th> {{$t('numberOfLost')}} </th>
                                <th> {{$t('winrate')}} </th>
                            </tr>
                            </thead>
                            <tbody class="sortable">
                            <tr v-for="(rank, index) in rankList.length" :key="rank" v-on:click="betDashboard(rankList[index])">
                                <td>
                                <span class="pl-2">{{rank}}</span>
                                </td>
                                <td> {{rankList[index].userName}} &nbsp;&nbsp;<i class="ti-layers" v-b-tooltip :title="$t('moreDetails')"></i></td>
                                <td> {{rankList[index].amount.toFixed(0)}} </td>
                                <td> {{rankList[index].total.toFixed(0)}} </td>
                                <td> {{rankList[index].roi.toFixed(0)}} %</td>
                                <td> {{rankList[index].won}} </td>
                                <td> {{rankList[index].lost}} </td>
                                <td> {{(rankList[index].won + rankList[index].lost) === 0 ? 'N/A' : (rankList[index].won / (rankList[index].won + rankList[index].lost) * 100).toFixed(0)}} %</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </perfect-scrollbar>
        </div>
        </div>
    </div>
</template>

<script>
import countdown from 'vuejs-countdown';

export default {
  name: 'bet-rank',
  components: {
    countdown
  },
  data() {
    return {
    }
  },
  props: ["rankList", "title", "deadline"],
  computed: {
    endDate() {
      return this.GetDate(this.deadline, 'YYYY-MM-DD HH:mm:ss');
    }
  },
  methods: {
    betDashboard (linkId) {
      this.$root.$emit('openUserDetailDialog', linkId);
    },
  }
}
</script>

<style lang="scss" scoped>
.overflow-height {
  height: 500px;
}
.sortable tr {
  cursor: pointer;
}
</style>